import VueRouter from 'vue-router';


let routes = [
    {path: '/', component: require('./views/Homepage.vue').default},
    {
        path: '*',
        redirect: '/',
    },
];

export default new VueRouter({
    routes,
    mode: 'history',
});
