<template>
    <v-card color="grey darken-4">
        <v-container class="text-center">
            <v-row justify="center">
                <v-col cols="12" class="display-1 pt-10 pb-10">
                    <b>Desktop Computer Builds</b>
                </v-col>
                <v-col cols="12" lg="3" sm="6" class="pricing-info pb-10 px-6">
                    <p class="subtitle-1"><b>Schedule A Free Consultation!</b></p>
                    Contact us to setup an appointment to speak with one of our engineers about building your desktop.
                    Our staff will show you consult with you about your desired build and answer any questions you have
                    about the machines.
                </v-col>
                <v-col cols="12" lg="3" sm="6" class="pricing-info pb-10 px-6">
                    <p class="subtitle-1"><b>Pick Your Parts - $250</b></p>
                    Once your deposit is placed, our engineer will create a cart based on your budget and computer
                    requirements for you to purchase. You can purchase the parts in the cart off Ebay, Amazon or Newegg.
                    Some retailers will offer financing for your computer build with 0% interest!
                </v-col>
                <v-col cols="12" lg="3" sm="6" class="pricing-info pb-10 px-6">
                    <p class="subtitle-1"><b>Your Machine Is Built - $250</b></p>
                    When the parts arrive at our office we will hand assemble your machine and install the desired OS of
                    your choosing. (Windows Professional +$100 or Ubuntu Linux). When the machine is ready, we will
                    contact you to schedule a pickup!
                </v-col>
                <v-col cols="12" lg="3" sm="6" class="pricing-info pb-10 px-6">
                    <p class="subtitle-1"><b>We Stands Behind Our Builds</b></p>
                    We offer free labor for repairs on our machine for 1 year since the purchase date.
                    We will also discount any repair by 10% after the 1 year has ended.
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    export default {
        name: "Pricing"
    }
</script>

<style scoped>

</style>
