import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';
import Vuelidate from 'vuelidate';
import axios from 'axios';

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(Vuetify, {
    theme: {
        primary: '#00796b', //teal darken-2
        secondary: '#673ab7', //deep-purple base
        accent: '#1976D2', //blue darken-2
        info: '#3F51B5', //indigo
        success: '#4CAF50', //green base
        error: '#FF5252', //red base
        warning: '#FF8F00', //amber darken-3 base
        activated: '#00796b', //teal darken-2
        disabled: '#757575', //grey darken-1

        monday: '#B71C1C', //red darken-4
        tuesday: '#EF6C00', //orange darken-3
        wednesday: '#F9A825', //yellow darken-3
        thursday: '#388E3C', //green darken-2
        friday: '#2196F3', //blue
        saturday: '#3F51B5', //indigo
        sunday: '#9C27B0', //purple
    },
});
window.Vue = Vue;
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
else console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
