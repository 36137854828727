<template>
    <v-card color="grey darken-3">
        <v-container class="text-center">
            <v-row justify="center">
                <v-col cols="12" class="display-1 pt-10 pb-10">
                    <b>Locations</b>
                </v-col>
                <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                    <p class="subtitle-1"><b>Beacon/Fishkill</b></p>
                    9 Cynwyd Drive, Fishkill, NY 12524
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    export default {
        name: "Pricing"
    }
</script>

<style scoped>

</style>
