require('./bootstrap');
import router from './routes';
import vuetify from './vuetify'

Vue.component('pricing', require('./views/components/Pricing.vue').default);
Vue.component('computerBuilds', require('./views/components/ComputerBuilds.vue').default);
Vue.component('locations', require('./views/components/Locations.vue').default);

new Vue({
    el: '#app',
    vuetify,
    router,
});
