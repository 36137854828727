<template>
    <div id="app">
        <v-app>
            <v-card dark color="black" class="text-center px-5 py-1">
                PC & Mac Computer Repairs, Tube Amplifier Repairs, New and Used – Desktops and Laptops
            </v-card>

            <v-card flat tile color="deep-orange darken-4">
                <v-card flat tile color="deep-orange darken-4" class="d-flex flex-column flex-md-row ml-auto mr-auto"
                        max-width="1100px">
                    <div class="d-flex mr-md-auto">
                        <img src="/images/logo.png" alt="" style="max-width:150px; margin: 0 0 0 10px">
                    </div>
                    <div class="d-flex flex-column flex-md-row mt-md-10">
                        <p class="header-info subtitle-1 px-5 pt-2">
                            <v-icon class="pr-2" color="black">mdi-clock-time-five-outline</v-icon>
                            <b>Call For Appt.
                                <br>
                                <a href="tel:8452433119">+1 ‪(845) 243-3119‬</a>
                            </b>
                        </p>
                        <p class="header-info subtitle-1 px-5 pt-2">
                            <v-icon class="pr-2" color="black">mdi-email</v-icon>
                            <b>Email Us
                                <br>
                                <a href="mailto:hudsonvalleycomputers@gmail.com">hudsonvalleycomputers@gmail.com</a>
                            </b>
                        </p>
                        <p class="header-info2 subtitle-1 px-5 pt-2">
                            <v-icon class="pr-2" color="black">mdi-phone</v-icon>
                            <b>Opening Time
                                <br>
                                <span class="black--text">
                                Mon-Fri 10AM to 6PM
                                <br>
                                Sat-Sun 11AM to 5PM
                            </span>
                            </b>
                        </p>
                    </div>
                </v-card>
            </v-card>

<!--            <div class="revslider" data-alias="homepage"></div>-->

            <v-content>
                <pricing></pricing>
                <computerBuilds></computerBuilds>
                <locations></locations>
            </v-content>

            <v-footer app>
                <span class="ml-auto caption">Paul Livorsi | Hudson Valley Computers | &copy; 2020</span>
            </v-footer>
        </v-app>
    </div>
</template>

<script>
    export default {
        props: {
            source: String,
        },
        data: () => ({
            drawer: false,
            justify: [
                'start',
                'end',
                'center',
                'space-between',
                'space-around',
            ],
        }),

        created() {
            this.$vuetify.theme.dark = true
        },
    }
</script>

<style scoped>
    .header-info {
        border-right: 1px solid rgba(250, 250, 250, 0.1);
    }

    @media only screen and (max-width: 960px) {
        .header-info {
            border-top: 1px solid rgba(250, 250, 250, 0.1);
            border-right: none;
        }

        .header-info2 {
            border-top: 1px solid rgba(250, 250, 250, 0.1);
        }
    }
</style>
