<template>
    <v-container class="text-center">
        <v-row justify="center">
            <v-col cols="12" class="display-1 pt-10 pb-10">
                <b>Computer/Laptop and Tube Amplifier Repair Pricing</b>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>ESTIMATES</b></p>
                **We do not charge and estimate if you purchase the service**
                Starting at $49.95 - billed by the hour
                Call To Drop Off In Our Office Or
                Schedule An Appointment For On-Site/In Home Service (Add $40.00)
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>Amplifier Repair</b></p>
                Guitar and Bass tube amplifier repairs. We service all brands of amplifiers.
                Billed at a $100 an hour for diagnostics and repairs.
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>ON-SITE HOURLY RATE</b></p>
                $99.95 per hour
                Fast, Organized, Clean and Professional Service for your Business or Home
                THE MINIMUM ONSITE SERVICE CHARGE PER HOUR is $149.95, Until 90 minutes
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>VIRUS REMOVAL</b></p>
                Drop Off Repair  Starting at   $99.95
                Virus Removal
                Tune Up/Maintenance
                Registry and Start-up Optimization
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>OS RECOVERY/FACTORY RESET</b></p>
                Drop Off Repair at $119.95
                Full Clean Install Of Your OS
                Anti-Virus Setup
                All Windows or Mac Updates
                Latest Drivers Installed
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>DATA BACKUP/TRANSFER UP TO 1000GB</b></p>
                Starting   at   $149.95
                If you need a backup bigger than 1000GB, please call for Price
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>DATA BACKUP & OS RECOVERY</b></p>
                Drop Off Repair   at   $199.95
                Full Clean Install Of Your OS
                Anti-Virus Setup
                All Windows or Mac Updates
                Latest Drivers Installed
                Data Backup up to 250GB
                +$50 for anything over 250GB up to 1000GB
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>PC LAPTOP SCREEN</b></p>
                Drop Off Repair  Starting at   $99.95 to $249.95
                **Part Not Included, Most Of The Screens Cost Between $60 And $250
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>MOTHERBOARD REPLACEMENT</b></p>
                Drop Off Repair Starting at $189.95
                Replacement Of Defective Motherboard
                **Parts Not Included
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>LAPTOP POWER JACK REPLACEMENT/REPAIR</b></p>
                Drop Off Repair  Starting at   $119.95
                **Part & Service are included for most laptops
                ** Repairs that involve soldering will cost more
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>LAPTOP KEYBOARD REPLACEMENT/REPAIR</b></p>
                Drop Off Repair   at   $69.95
                **Keyboard not included, most of the keyboards cost between $30 & $75
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>LAPTOP INTERNAL HARDWARE REPLACEMENT/REPAIR</b></p>
                Drop Off Repair  Starting at   $79.95
                CPU, CPU Fans, CPU Cooling System Clean Up & Other Repairs That Require Opening The Laptop Case To
                Resolve The Proble
                **DOES NOT Include Motherboard, Power Jack, Screen, Keyboard: See Specific Prices For Those Services**
                **Parts Not Included**
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>OTHER HARDWARE/SOFTWARE IN-STORE SERVICE</b></p>
                Drop Off Repair   at   $49.95
                Misc. Hardware/Software Issues
                **Parts Not Included
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>DATA RECOVERY STAGE 1</b></p>
                Starting   at   $189.95 To $299.95
                Rescue and Save Secure Copies Of Your Computer’s Files In Case Of Hard Drive Is Corrupted Or Formatted
                Up To 1000GB Of Your Computer’s Files Will Be Recovered. If the data recovery fails, we charge $150 for
                the labor and hardware used in the attempt to recover data.
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>DATA RECOVERY STAGE 2</b></p>
                Starting at $500 to $2500
                This repair is for HDD’s that are clicking, skipping, making noise or the motor is not spinning. Using
                our clean room facility; we safely remove the magnetic platters from the broken drive and install them
                in a working drive of the same type, allowing us to read the platters and recover your data. If the data
                recovery fails, we charge $350 for the labor and hardware used in the attempt to recover data.
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" class="pricing-info pb-10 px-6">
                <p class="subtitle-1"><b>HARD DRIVE IMAGE + CLONE</b></p>
                Drop Off Repair   at   $149.95
                We Reccomend to Replace Your Current HDD When It Is 3 Years Old
                Other signs of possible failure are loud noises, Computer Is Running Slow
                This Service Will Keep All Your Data, Programs & The Same Current Configuration From The Old Computer
                Requirements: The Computer Needs To Be Booting To Windows Normally Without Any Virus Infections
                **New Hard Drive Cost Is Not Included
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Pricing"
    }
</script>

<style scoped>
    .pricing-info {
        border-right: 1px solid rgba(250, 250, 250, 0.1);
        border-left: 1px solid rgba(250, 250, 250, 0.1);
        border-top: 1px solid rgba(250, 250, 250, 0.1);
        border-bottom: 1px solid rgba(250, 250, 250, 0.1);
    }

    @media only screen and (max-width: 600px) {
        .pricing-info {
            border-right: none;
            border-left: none;
        }
    }
</style>
